import React, { Suspense, useEffect } from 'react';
import { useSpring } from 'react-spring';

import { Container } from 'react-bootstrap';

// PAGES
import Layout from '../layout/Layout';
import Home from './blackFriday/pages/homeTop/Home';
import Video from './blackFriday/pages/video/Video';
import Unique from './blackFriday/pages/unique/Unique';
import Earn from './blackFriday/pages/earn/Earn';
import ForMe from './blackFriday/pages/forMe/ForMe';
import AboutUs from './blackFriday/pages/aboutUs/AboutUs';
import Program from './blackFriday/pages/program/Program';
import Testimonials from './blackFriday/pages/testimonials/Testimonials';
import Prices from './blackFriday/pages/prices/Prices';
import JoinUs from './blackFriday/pages/joinUs/JoinUs';
import Faq from './blackFriday/pages/faq/Faq';
import ExitPopUp from '../components/exitPopUp/ExitPopUp';
import Footer from '../components/footer/Footer';

import svTicket from '../assets/img/blackFriday/ticketSV.svg';

// const Video = React.lazy(() => import('./landing/pages/video/Video'));
// const Unique = React.lazy(() => import('./landing/pages/unique/Unique'));
// // const Earn = React.lazy(() => import('./landing/pages/earn/Earn'));
// const ForMe = React.lazy(() => import('./landing/pages/forMe/ForMe'));
// const AboutUs = React.lazy(() => import('./landing/pages/aboutUs/AboutUs'));
// const Program = React.lazy(() => import('./landing/pages/program/Program'));
// const Prices = React.lazy(() => import('./landing/pages/prices/Prices'));
// const JoinUs = React.lazy(() => import('./landing/pages/joinUs/JoinUs'));
// const Faq = React.lazy(() => import('./landing/pages/faq/Faq'));
// const Footer = React.lazy(() => import('../components/footer/Footer'));

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const Main = (props) => {
  const calc = (x, y) => [
    x - window.innerWidth / 2,
    y - window.innerHeight / 2,
  ];
  const [properties, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  useEffect(() => {
    function appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    }

    window.addEventListener('resize', appHeight);
    appHeight();
  }, []);

  return (
    <Layout>
      <Container
        fluid
        id="inicio"
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <div className="textureBack" />
        <Home />
        <Video />
        <div id="fixed-target">
          <div className="blackFridayMobleSticky">
            <a href="#inscribirse">
              <img
                src={svTicket}
                alt=""
                className="blackFridayFixed d-none d-lg-block"
              />
            </a>

            <div className="blackFridayStickyDivAbsolute">
              <div className="blackFridayStickyDiv d-block d-lg-none">
                <a href="#inscribirse">
                  <img src={svTicket} alt="" className="blackFridayFixed" />
                </a>
              </div>
            </div>
            <Unique />
            <Earn />
            <ForMe />
            <AboutUs />
            <Program />
            <Testimonials />
            <Prices />
            <Faq />
            <JoinUs />
          </div>
        </div>
        <Footer />
      </Container>
    </Layout>
  );
};

export default Main;
