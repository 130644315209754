import React from 'react';
import { CardDeck, Card, Row, Col } from 'react-bootstrap';
import './PricesSection.scss';

import svTitle from '../../../../assets/img/blackFriday/svTitle.svg';

export const PricesSection = (props) => {
  const { discount } = props;
  return (
    <div className="pricesContainer cardPrices" id="inscribirse">
      <div className="container paymentContainer">
        <div className="row align-items-center">
          <div className="col-12 text-center pricesCol">
            <Row className="mb-5">
              <Col
                xs={12}
                lg={6}
                className="d-flex justify-content-lg-center align-items-center priceBfCol"
              >
                <img src={svTitle} alt="" className="bfPrice" />
              </Col>

              <Col
                xs={12}
                lg={6}
                className="d-flex justify-content-start align-items-center"
              >
                <h1
                  className="pricesSectionTitleBf h2Title"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Selecciona tu
                  <br />
                  <span className="boldText">método de pago</span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <CardDeck data-aos="fade-up" data-aos-duration="1000">
                  <Card className="cardStyle d-md-flex">
                    <Card.Body className="d-md-block bodyCardPrices">
                      <Card.Text className="iconPricesTop">
                        <span>
                          <span className="h3Title">Tarjeta de Crédito</span>
                          <span className="paymentTypeTitle">
                            PAGO ÚNICO (eur)
                          </span>
                        </span>
                      </Card.Text>
                      <div className="pricesTexts">
                        <Card.Text className="beforePrice">
                          <span className="striked-price">160€</span>
                        </Card.Text>

                        <Card.Text className="afterPrice">
                          {discount.price_eur}€
                        </Card.Text>
                        <span className="after pText">AHORA</span>
                      </div>
                      <Card.Text className="iconPricesBottom">
                        <a
                          href={discount.eur_url}
                          className="buttonsPrices buttonImage"
                        >
                          <span>PAGAR</span>
                        </a>
                      </Card.Text>
                    </Card.Body>
                    <div className="topLine"></div>
                    <div className="rightLine"></div>
                    <div className="leftLine"></div>
                    <div className="bottomLine"></div>
                  </Card>
                  <Card
                    className="cardStyle d-md-flex"
                    // data-aos="fade-up"
                    // data-aos-duration="1000"
                  >
                    <Card.Body className="d-md-block bodyCardPrices">
                      <Card.Text className="iconPricesTop">
                        <span>
                          <span className="h3Title">PAYPAL</span>
                          <span className="paymentTypeTitle">
                            Pago único (usd)
                          </span>
                        </span>
                      </Card.Text>
                      <div className="pricesTexts">
                        <Card.Text className="beforePrice">
                          <span className="striked-price">$174</span>
                        </Card.Text>

                        <Card.Text className="afterPrice">
                          ${discount.price_usd}
                        </Card.Text>
                        <span className="after pText">AHORA</span>
                      </div>
                      <Card.Text className="iconPricesBottom">
                        <a
                          href={discount.usd_url}
                          className="buttonsPrices buttonImage"
                        >
                          <span>PAGAR</span>
                        </a>
                      </Card.Text>
                    </Card.Body>
                    <div className="topLine"></div>
                    <div className="rightLine"></div>
                    <div className="leftLine"></div>
                    <div className="bottomLine"></div>
                  </Card>
                </CardDeck>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricesSection;
