import React, { useEffect, useState } from 'react';
import { Jumbotron, Button, Row, Col, Container } from 'react-bootstrap';
import './GridHome.scss';

import { Parallax } from 'react-scroll-parallax';

import { isMobile } from '../../../../layout/Layout';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Slider from 'react-slick';

import dotSvg from '../../../../assets/img/blackFriday/dot.svg';

import svTitle from '../../../../assets/img/blackFriday/svTitle.svg';

export const GridHome = (props) => {
  const mobile = isMobile();

  const [settings, setSettings] = useState({
    customPaging: function (i) {
      return (
        <a>
          <img src={dotSvg} alt="" />
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    fade: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    pauseOnFocus: true,
  });

  return (
    <Container fluid>
      <Row className="rowHome">
        <Col
          xs={12}
          lg={4}
          className="homeCol imgHomeCol"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <Parallax
            className="custom-class d-none d-lg-block"
            x={[-20, 20]}
            tagOuter="figure"
            disabled={mobile}
          >
            <div className="imgContainer">
              <img
                className="imgOlivia"
                src={require('../../../../assets/img/photos/1---Twerkealo-min-min.png')}
                data-aos="zoom-in"
                data-aos-duration="1000"
                alt=""
              ></img>
            </div>
          </Parallax>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="homeCol homeColContent"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <Slider {...settings}>
            <div className="slickSliderDiv">
              <Jumbotron className="jumbotronHome">
                <img src={svTitle} alt="" className="bfTitle" />
                <br />
                <p className="pText">
                  Aprovecha el descuentazo que trae San Valentín. Enamórate de
                  este estilo de baile y aprende a sacar el ritmo que llevas
                  dentro,
                  <br />
                  <span className="pText textBold">¡y a mover el booty!</span>
                </p>
                <Button
                  href="#inscribirse"
                  bsPrefix
                  className="buttonText buttonImage m-auto"
                  size="lg"
                >
                  CONSEGUIR DESCUENTO
                </Button>
              </Jumbotron>
            </div>
            <div className="slickSliderDiv">
              <Jumbotron className="jumbotronHome">
                <h1 className="h1Title">twerkealo</h1>
                <p className="pText">
                  Descubre cómo Explotar tu Sensualidad y Convertirla en ARTE a
                  través del Twerk, Incluso si crees que el Baile no es para ti.
                </p>
                <Row className="buttonplay justify-content-center">
                  <Button
                    href="#inscribirse"
                    bsPrefix
                    className="buttonText buttonImage"
                    size="lg"
                  >
                    Únete a Twerkealo
                  </Button>
                  <Button
                    href="#video"
                    bsPrefix
                    className="buttonText buttonImage2"
                    size="lg"
                    style={{ color: '#000' }}
                  >
                    Ver Video
                  </Button>
                </Row>
              </Jumbotron>
            </div>
          </Slider>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="homeCol imgHomeCol"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          <Parallax
            className="custom-class d-none d-lg-block"
            x={[20, -20]}
            tagOuter="figure"
            disabled={mobile}
          >
            <div className="imgContainer">
              <img
                className="imgLucia"
                src={require('../../../../assets/img/photos/2---Twerkealo-min-min.png')}
                data-aos="zoom-in"
                data-aos-duration="1000"
                alt=""
              ></img>
            </div>
          </Parallax>
          {/* <div className="imgContainer d-block d-lg-none">
            <img
              className="imgOlyLuci"
              src={require('../../../../assets/img/photos/10---Twerkealo-min-min-min.png')}
              data-aos="zoom-in"
              data-aos-duration="1000"
              alt=""
            ></img>
          </div> */}
        </Col>
      </Row>
      {/* <div className="videoHomePage d-flex d-lg-none">
            <ResponsiveEmbed aspectRatio="16by9">
               <embed
                  type="image/svg+xml"
                  src="https://player.vimeo.com/video/349625217"
               />
            </ResponsiveEmbed>
            <h4 className="videoText buttonTitle">VER PROGRAMA</h4>
         </div> */}
    </Container>
  );
};

export default GridHome;
