import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Cursor from '../components/cursor/Cursor';
import Canvas from '../components/canvas/Canvas';
import Navbar from '../components/navbar/Navbar';
import NavbarBf from '../views/blackFriday/navbar/Navbar';

// STYLE
import './Layout.css';

// Libraries
import AOS from 'aos';
import SmoothScroll from 'smooth-scroll';

// const imagemin = require('imagemin');
// const imageminPngquant = require('imagemin-pngquant');

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua);
};

export const Layout = (props) => {
  useEffect(() => {
    AOS.init({ disable: window.innerWidth < 991 });
    new SmoothScroll('a[href*="#"]', { offset: 50 });
  });

  // (async () => {
  //   const files = await imagemin(['images/*.{png}'], {
  //     destination: 'build/static/media',
  //     plugins: [
  //       imageminPngquant({
  //         quality: [0.6, 0.8],
  //       }),
  //     ],
  //   });

  //   console.log(files);
  //   //=> [{data: <Buffer 89 50 4e …>, destinationPath: 'build/images/foo.jpg'}, …]
  // })();
  const dateActive = new Date('2021-02-02T16:00:00');
  const dateExpire = new Date('2021-02-15T00:01:00');
  const todayDate = new Date();

  return (
    <Container nogutters="true" fluid id="inicio">
      {typeof navigator !== 'undefined' && isMobile() ? null : <Canvas />}
      {todayDate < dateActive && todayDate < dateExpire ? (
        <Navbar />
      ) : todayDate > dateActive && todayDate < dateExpire ? (
        <NavbarBf />
      ) : (
        <Navbar />
      )}
      {typeof navigator !== 'undefined' && isMobile() ? null : <Cursor />}
      {props.children}
    </Container>
  );
};

export default Layout;
