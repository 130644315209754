import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const UniqueSection = () => {
  return (
    <Row
      className="uniqueSectionRow uniqueSectionRowBf h-100 justify-content-center"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-offset="-400"
    >
      <Col xs={12} lg={6}>
        <h2 className="h2Title">¿Por qué Twerkealo es Diferente?</h2>
        <img
          alt=""
          className="uniqueImg stickyTopNav"
          src={require('../../../../assets/img/photos/3---Twerkealo-min-min.png')}
        ></img>
      </Col>
      <Col
        xs={12}
        lg={(4, { offset: 1 })}
        className="uniqueContent"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title">Twerkealo es único:</h2>
          <p className="pText">
            Un Programa que le dará al Twerk la importancia que merece,
            desmontemos tabúes y normalicemos este estilo de baile como
            cualquier otro. Y tú podrás ser parte de todo esto, aprender un
            ritmo único, sensual e incomparable. Un curso donde disfrutarás y
            ejercitarás al mismo tiempo.
          </p>
          <div className="whiteLine"></div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title"> Transformación Inmediata:</h2>
          <p className="pText">
            Porque no es un simple curso de danza que te enseña unos pasos
            sencillos. Es un Programa completo que te lleva de 0 a 100 en el
            Twerk. Dirás adiós a los complejos y le darás rienda suelta a lo que
            sientes para comunicarlo a través de tu baile. Como siempre decimos
            “El Culo es la Fuerza de la mujer” y aquí te enseñaremos a moverlo
            como tu quieras y al ritmo que desees para lograr una sensualidad
            que te represente.
          </p>
          <div className="whiteLine"></div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title"> Aumenta la Confianza en ti misma:</h2>
          <p className="pText">
            Aprenderás a convertir tu sensualidad en arte. Los pasos y
            coreografías que te enseñaremos, te permitirán sentirte más segura
            de ti misma y que todas las miradas estén sobre ti. Podrás
            expresarte a través del baile. Es un programa donde no hay juicios
            ni críticas, sólo diversión, ejercicio y sensualidad.
          </p>
          <div className="whiteLine"></div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          className="uniqueDivContent"
        >
          <h2 className="h2Title"> Abajo las Restricciones:</h2>
          <p className="pText">
            Porque no hay imposiciones ni límites, lo podrás aprender cuando
            quieras, donde quieras y como quieras. Te conectarás con las raíces
            del Twerk y aprenderás a sentir verdaderamente la música y conectar
            tus sentimientos con tu cuerpo para lograr un ritmo perfecto.
          </p>
          <div className="whiteLine"></div>
        </div>
      </Col>
      <Col xs={12} className="uniqueColButton">
        <Button
          href="#inscribirse"
          bsPrefix
          className="buttonsUnique buttonImage"
        >
          <span className="buttonTitle">Acceder a Twerkealo</span>
        </Button>
      </Col>
    </Row>
  );
};

export default UniqueSection;
